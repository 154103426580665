"use server";

import { redirect } from "@solidjs/router";
import { getGiftById } from "../apis/server_apis";
import { GiftCardDetails } from "../types/pluto";
import { getCookie } from "vinxi/http";
import { Cookie } from "~/types";
import { APIError } from "~/utils/fetch";

export type PlutoInvoiceRouteData = {
  giftDetails?: GiftCardDetails;
};

export const getPlutoInvoiceRouteData = async (
  giftId: string
): Promise<PlutoInvoiceRouteData> => {
  let giftDetails: GiftCardDetails | undefined;

  let sid = getCookie(Cookie.SessionId);

  if (!sid) {
    throw redirect("/pluto/login");
  }

  try {
    giftDetails = await getGiftById(giftId, {
      throwAuthError: true,
    });

    if (giftDetails.paymentStatus !== "PAID") {
      throw redirect(`/pluto/create/${giftDetails?.id}`);
    }
  } catch (error) {
    if (error instanceof APIError && error.code === 401) {
      throw redirect("/pluto/login");
    }
    throw error;
  }

  return { giftDetails };
};
